$( window ).on("load", function() {
  //$("body").css("opacity", "1");

  checkCookies();

  /*** cookies ***/
  function checkCookies(){
    var cookiesAgreement = localStorage.getItem('npkrcm-cookies-agreement'),
    cookiesNote = $('.cookies-notification'),
    cookiesBtn = $('.cookies-notification__btn'),
    cookiesBtnClose = $('.cookies-notification__close');

    if(!cookiesAgreement){
      cookiesNote.addClass('show');
    }

    cookiesBtn.add(cookiesBtnClose).on( 'click', function() {
      localStorage.setItem( 'npkrcm-cookies-agreement', "true");
      cookiesNote.removeClass('show');
    });
  }

  $(window).scroll(function() {
  var scroll = $(window).scrollTop();
  $(".zoom img").css({
    transform: 'translate3d(0, -'+(scroll/100)+'%, 0) scale('+(100 + scroll/25)/100+')',
    //Blur suggestion from @janwagner: https://codepen.io/janwagner/ in comments
    //"-webkit-filter": "blur(" + (scroll/200) + "px)",
    //filter: "blur(" + (scroll/200) + "px)"
  });
});


/*** accordion ***/
$('.acc').on('click', '.acc__btn', function() {
  var accThis = $(this).closest('.acc'),
      accContentThis = accThis.find('.acc__content'),
      accGroup = accThis.parent('.acc-group'),
      accAllInGroup = accGroup.find('.acc'),
      accContentAllInGroup = accAllInGroup.find('.acc__content'),
      accActiveClass = '--active',
      accGroupTypeClass = 'acc-group--single';


  if (accGroup.hasClass(accGroupTypeClass)) {
    accContentAllInGroup
      .not(accContentThis)
      .slideUp(200, function(){
        accAllInGroup.not(accThis).removeClass(accActiveClass);
    });
  }

  accThis.toggleClass(accActiveClass);
  accContentThis.slideToggle(300);

  setTimeout(function() {
    if (accContentThis.css('display') == 'none') {
      accThis.removeClass(accActiveClass);
    }
  }, 200);
});


/*** Бургер ***/
// $('.burger-btn').click(function(){
//   $(this).toggleClass('open');
//   $(".burger-menu").toggleClass('isOpen');
// });

/* Мобильное меню */
$(".menu-toggle").on('touchstart click', function(e){
  e.preventDefault();

  $(this).toggleClass('open');
  $(".mobile-menu").toggleClass('mobile-menu--opened');

  if ($(".mobile-menu").hasClass('mobile-menu--opened')){
    $('body').addClass('--noscroll');
  }else {
    $('body').removeClass('--noscroll');
  }
});

/*** Шапка сайта при скоролле ***/
var header = $('.menu-scrolled'),
		scrollPrev = 0;

$(window).scroll(function() {
	var scrolled = $(window).scrollTop();

	if ( scrolled > 80 && scrolled > scrollPrev) {
		header.addClass('--hide');
    //$('.filters').addClass('--out');
	} else {
		header.removeClass('--hide');
    //$('.filters').removeClass('--out');
	}
	scrollPrev = scrolled;
});



  /*** 0 к пагинации слайдеров ***/
  function addZero(num){
    return (num > 9) ? num : '0' + num;
  }



  /*** слайдер промо ***/
  var swiperPromo = new Swiper('.slider-promo .swiper-container', {
    autoHeight: true,
    loop: true,
    speed: 600,
    slidesPerView: 'auto',
    //centeredSlides: true,
    //spaceBetween: 20,

    pagination: {
      el: '.slider-promo .slider__pagination',
      type: 'fraction',
      formatFractionCurrent: addZero,
      formatFractionTotal: addZero
    },

    navigation: {
      nextEl: '.slider-promo .slider__btn-next',
      prevEl: '.slider-promo .slider__btn-prev',
    }
  });

  /*** слайдер на всю ширину ***/
  var swiperFullWidth = new Swiper('.slider-fullwidth .swiper-container', {
    autoHeight: true,
    loop: true,
    speed: 600,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 20,

    pagination: {
      el: '.slider-fullwidth .slider__pagination',
      type: 'fraction',
      formatFractionCurrent: addZero,
      formatFractionTotal: addZero
    },

    navigation: {
      nextEl: '.slider-fullwidth .slider__btn-next',
      prevEl: '.slider-fullwidth .slider__btn-prev',
    }
  });



  /*** слайдер стандартный ***/
  // var swiper_list = $("[data-slider-id]");
  // $(swiper_list).each(function() {
  //   let swiper_slider_id = $(this).attr('data-slider-id');
  //
  //   var mySwiper = new Swiper(".swiper-container", {
  //       spaceBetween: 10,
  //       observer: true,
  //       observeParents: true,
  //       navigation: {
  //           nextEl: ".slider__btn-next",
  //           prevEl: ".slider__btn-prev",
  //       },
  //   });
  // });

  $('.slider-default').each(function (i) {
    var slider_class = 'slider_' + i,
    slider_init = '.slider_' + i,
    next_slider = 'slider--next_' + i,
    prev_slider = 'slider--prev_' + i,
    pagination_slider = 'slider-pagination_' + i;

    $(this).find('.slider__container').addClass(slider_class);
    $(this).find('.slider__btn-prev').addClass(prev_slider);
    $(this).find('.slider__btn-next').addClass(next_slider);
    $(this).find('.slider__pagination').addClass(pagination_slider);

    var swiperDefault = new Swiper(slider_init, {
      autoHeight: true,
      loop: true,
      speed: 500,
      slidesPerView: 'auto',
      centeredSlides: false,
      observer: true,
      observeParents: true,

      pagination: {
        el: ('.' + pagination_slider),
        type: 'fraction',
        formatFractionCurrent: addZero,
        formatFractionTotal: addZero
      },

      navigation: {
        nextEl: ('.' + next_slider),
        prevEl: ('.' + prev_slider),
      }
    });
  });

  /*** tabs ***/
  $('.tabs').find(".tabs__content-item:first").show();
  $('.tabs__nav').on('click', '.tabs__nav-item:not(.tabs__nav-item--active)', function() {
    $(this)
    .addClass('tabs__nav-item--active')
    .siblings()
    .removeClass('tabs__nav-item--active')
    .closest('.tabs')
    .find('.tabs__content-item')
    .hide()
    .eq($(this).index())
    .fadeIn();

    //swiper.destroy(false,true);
    // $('.slider-default').each(function (i) {
    //   var mySwiper = $('.slider_' + i).swiper;
    //   mySwiper.init();
    // });
  });

  /*** tabs nav слайдер ***/
  var swiperTabs = new Swiper('.tabs__nav', {
    slidesPerView: 'auto',
    speed: 300,
    //simulateTouch: false,
    freeMode: true,
    roundLengths: true,

    navigation: {
      nextEl: '.tabs-nav__control .slider__btn-next',
    },

    breakpoints: {
      320: {
        centeredSlides: false,
      },
      1023: {
        centeredSlides: false,
      },
    }

  });





  /*** popup ***/
  var $modalOpen = $('.modal-open'),
  $modalClose = $('.modal__close');

  $modalOpen.magnificPopup({
    type: 'inline',
    preloader: false,
    fixedContentPos: true,
    showCloseBtn: false,
    mainClass: 'mfp-move',
    removalDelay: 300
  });

  $modalClose.on('click', function(e) {
    e.preventDefault();
    $.magnificPopup.close();
  });

  /*** popup callback send ***/
  //AjaxForm.Message.success = function() {};
  $(document).on('af_complete', function(event, response) {
    var form = response.form;

    if (response.success) {
      $.magnificPopup.open({
        items: {
          src: '#modal-thanks'
        }
      });

    }
  });

  /*** file attachment ***/
  $('.modal-form__attachment-input').change(function(e){
    $(".modal-form__attachment-files").text("");

    for (var i = 0; i < e.target.files.length; i++) {
      $(".modal-form__attachment-files").append(e.target.files[i].name + "; ");
    }
    $(".modal-form__attachment-control").find("span").text("Выбрано файлов: " + e.target.files.length);
  });



  var $range = $(".js-range-slider"),
      $inputFrom = $(".js-input-from"),
      $inputTo = $(".js-input-to"),
      instance,
      min = $inputFrom.val(),
      max = $inputTo.val(),
      from = 0,
      to = 0;

  $range.ionRangeSlider({
  	  skin: "round",
      type: "double",
      min: min,
      max: max,
      from: $inputFrom.val(),
      to: $inputTo.val(),
      onStart: updateInputs,
      onChange: updateInputs
  });
  instance = $range.data("ionRangeSlider");

  function updateInputs (data) {
  	from = data.from;
      to = data.to;

      $inputFrom.prop("value", from);
      $inputTo.prop("value", to);
  }

  $inputFrom.on("input", function () {
      var val = $(this).prop("value");

      // validate
      if (val < min) {
          val = min;
      } else if (val > to) {
          val = to;
      }

      instance.update({
          from: val
      });
  });

  $inputTo.on("input", function () {
      var val = $(this).prop("value");

      // validate
      if (val < from) {
          val = from;
      } else if (val > max) {
          val = max;
      }

      instance.update({
          to: val
      });
  });


/*** gallery ***/
  function magnificPrev(e) {
      e.preventDefault();
      var magnificPopup = $.magnificPopup.instance;
      magnificPopup.prev();
      return false;
  }

  function magnificNext(e) {
      e.preventDefault();
      var magnificPopup = $.magnificPopup.instance;
      magnificPopup.next();
      return false;
  }

  $(document).off('click', '.js-arrow-popup-prev', magnificPrev).on('click', '.js-arrow-popup-prev', magnificPrev);
  $(document).off('click', '.js-arrow-popup-next', magnificNext).on('click', '.js-arrow-popup-next', magnificNext);


var swiper = new Swiper('.qwe', {
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev',
    slidesPerView: 2,
    centeredSlides: false,
    spaceBetween: 0,
    //loop: true
});

$('.js-gallery').each(function() {
  $(this).magnificPopup({
    delegate: 'a',
    type: 'image',
    removalDelay: 500,
    closeOnContentClick: false,
    closeBtnInside: false,
    mainClass: 'mfp-with-zoom mfp-img-mobile',
    closeMarkup: '<button title="%title%" class="mfp-close" style="position: absolute; top: 30px; right: -15px"><img src="/Content/images/close-icon.png" width="25" height="29"/></button>',

    image: {
      markup: '<div class="gallery mfp-figure">'+
            '<div class="mfp-close"></div>'+
            '<div class="mfp-img"></div>'+
            '<div class="mfp-bottom-bar">'+
              '<div class="mfp-title"></div>'+
              '<div class="mfp-counter"></div>'+
            '</div>'+
          '</div>',

       verticalFit: true,
       titleSrc: function(item) {
           return item.el.attr('title') + ' &middot; <a class="image-source-link" href="'+item.el.attr('data-source')+'" target="_blank">image source</a>';
       }
   },

    gallery: {
        enabled: true,
        navigateByImgClick: true,
        arrowMarkup: '',
      //  arrowMarkup: '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>', // markup of an arrow button
     tPrev: '',//title for left button
     tNext: '',//title for right button
     //tCounter: " %curr% / %total% "
     //tCounter: '<span class="mfp-counter">%curr% of %total%</span>'
     //tCounter: '<div class="gallery-control gallery-control--popup"><a href="#" class="control-item arrow-button arrow-button--left js-arrow-popup-prev"><-</a><div class="control-item count js-gallery-current-slide"><span class="js-unit">%curr%</span><sup class="js-gallery-slides-total">%total%</sup></div><a href="#" class="control-item arrow-button arrow-button--right js-arrow-popup-next">-></a></div>'
     tCounter: '<div class="slider__nav"><div class="slider__pagination">%curr% / %total%</div><div class="slider__control"><div class="js-arrow-popup-prev slider__btn slider__btn-prev btn-reset"><svg><use xlink:href="#i-arrow-right"></use></svg></div><div class="slider__btn js-arrow-popup-next slider__btn-next btn-reset"><svg><use xlink:href="#i-arrow-right"></use></svg></div></div></div>'
    },
    zoom: {
        enabled: true,
        duration: 300,
        opener: function (element) {
            return element.find('img');
        }
    }
  });
});



$('.image-link').magnificPopup({
		type: 'image',
		closeOnContentClick: true,
		closeBtnInside: false,
		mainClass: 'mfp-with-zoom mfp-img-mobile', // class to remove default margin from left and right side
		image: {
			verticalFit: true,
      // markup: '<div class="gallery mfp-figure">'+
      //       '<div class="mfp-close">123</div>'+
      //       '<div class="mfp-img"></div>'+
      //       '<div class="mfp-bottom-bar">'+
      //         '<div class="mfp-title"></div>'+
      //         '<div class="mfp-counter"></div>'+
      //       '</div>'+
      //     '</div>',
		},
		zoom: {
			enabled: true,
			duration: 300 // don't foget to change the duration also in CSS
		}
	});




});
